import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

const defaultState = {
  text: '',
  display: false,
  shownDuration: 5000,
  variant: 'success',
};

export const ToasterContext = React.createContext(defaultState);

export const ToasterProvider = ({ children }) => {
  const [state, setState] = useState({ ...defaultState });

  const showSnackbar = useCallback(
    /**
     * Function to show a toaster.
     * @param {string} text Text inside the toaster body
     * @param {string} variant Variant for snackbar
     * @param {number} timeout How many milliseconds before auto-hiding toaster. If set to null, toaster will not be auto-hidden
     */
    ({ text = '', variant = 'success', timeout = 5000 }) => {
      setState({
        text,
        variant,
        shownDuration: timeout,
        display: true,
      });
    },
    [],
  );

  const closeSnackbar = useCallback(
    /**
     * Function to hide a toaster.
     */
    () => {
      setState(prevState => ({ ...prevState, shownDuration: 0, display: false }));
    },
    [],
  );

  useEffect(() => {
    let timeout;

    if (state.shownDuration) {
      timeout = setTimeout(closeSnackbar, state.shownDuration);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [closeSnackbar, state.shownDuration]);

  return (
    <ToasterContext.Provider value={{ ...state, showSnackbar, closeSnackbar }}>{children}</ToasterContext.Provider>
  );
};

const ToasterConsumer = ToasterContext.Consumer;

export function withToaster(Component) {
  return function ToasterHOC(props) {
    return (
      <ToasterConsumer>
        {({ showSnackbar, closeSnackbar, ...toasterState }) => (
          <Component {...props} showSnackbar={showSnackbar} closeSnackbar={closeSnackbar} {...toasterState} />
        )}
      </ToasterConsumer>
    );
  };
}
ToasterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default ToasterContext;
