import { REACT_APP_RESTAPI_HOST } from '@constants/index';
export const NOTIF_ADMIN = {
  path: `${REACT_APP_RESTAPI_HOST}/notifikasi-admin`,
  method: 'GET',
};

export const SAVE_TOKEN_FCM = {
  path: `${REACT_APP_RESTAPI_HOST}/notifikasi-admin/save-token`,
  method: 'POST',
};
// export const STAFF_CREATE = {
//   path: `${REACT_APP_RESTAPI_HOST}/staff/save`,
//   method: 'POST',
// };
// export const STAFF_UPDATE = {
//   path: `${REACT_APP_RESTAPI_HOST}/staff/update`,
//   method: 'PUT',
// };
// export const STAFF_DETAIL = {
//   path: `${REACT_APP_RESTAPI_HOST}/staff/get`,
//   method: 'GET',
// };
// export const STAFF_DELETE = {
//   path: `${REACT_APP_RESTAPI_HOST}/staff/delete`,
//   method: 'DELETE',
// };
