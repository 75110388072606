/* eslint-disable no-console */
import React from 'react';
import { bool, func, node, string } from 'prop-types';

class ErrorBoundary extends React.PureComponent {
  static propTypes = {
    children: node,
    debug: bool,
    errorMessage: string,
    render: func,
  };

  static defaultProps = {
    children: <></>,
    debug: false,
    errorMessage: '',
    render: () => <></>,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    const { debug } = this.props;
    if (debug) {
      console.groupCollapsed(`Error occured!`);
      console.log('Error:', error);
      console.log('Info:', info);
      console.groupEnd();
    }

    this.setState({ hasError: true });

    if (window.__clientLogger) {
      window.__clientLogger.notify({
        error: {
          message: error.message,
          name: '[Benesse]',
        },
        params: {
          pageUrl: window.location.href,
          info,
        },
      });
    }
  }

  defaultRender = () => {
    const { errorMessage } = this.props;
    const message = errorMessage || 'Sorry, something went wrong.';

    return <div>{message}</div>;
  };

  render() {
    const { hasError } = this.state;
    const { children, render } = this.props;
    const renderError = render || this.defaultRender;

    return hasError ? renderError() : children;
  }
}

export default ErrorBoundary;
