import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ContextProvider from '@context/index';
import ErrorBoundary from '@components/common/ErrorBoundary';
import { ErrorPage5 as ErrorPage } from '@metronic/modules/ErrorsExamples/ErrorPage5';

import { ReactQueryConfigProvider, QueryCache, ReactQueryCacheProvider } from 'react-query';
import Routes from '@pages/index';
import { MaterialThemeProvider } from '@metronic/layout';
import 'dayjs/locale/id';

const queryConfig = {
  throwOnError: true,
  refetchAllOnWindowFocus: false,
  refetchOnMount: true,
  retry: 0,
};
const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <ErrorBoundary render={() => <ErrorPage />}>
      <MaterialThemeProvider>
        <Router>
          <ContextProvider>
            <ReactQueryCacheProvider queryCache={queryCache}>
              <ReactQueryConfigProvider config={queryConfig}>
                <Routes />
              </ReactQueryConfigProvider>
            </ReactQueryCacheProvider>
          </ContextProvider>
        </Router>
      </MaterialThemeProvider>
    </ErrorBoundary>
  );
};
export default App;
