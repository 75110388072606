import React, { Suspense, lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const MyProfile = lazy(() => import('@pages/MyProfile'));
const AttendanceTutor = lazy(() => import('@pages/@tutor/AttendanceTutor'));
const KarteStudent = lazy(() => import('@pages/@tutor/KarteStudent'));
const Student = lazy(() => import('@pages/@tutor/Student'));
const Schedule = lazy(() => import('@pages/@tutor/Schedule'));
const PointStudent = lazy(() => import('@pages/@tutor/PointStudent'));
const MainSchedule = lazy(() => import('@pages/@tutor/MainSchedule'));

const Dashboard = lazy(() => import('@pages/Dashboard'));
const TutorBasePage = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />

        <ContentRoute path="/dashboard" component={Dashboard} />
        <ContentRoute path="/my-profile" component={MyProfile} />
        <ContentRoute path="/tutor/attendance" component={AttendanceTutor} />
        <ContentRoute path="/tutor/karte" component={KarteStudent} />
        <ContentRoute path="/tutor/student" component={Student} />
        <ContentRoute path="/tutor/manage-student/" component={PointStudent} />
        <ContentRoute path="/tutor/schedule" component={Schedule} />
        <ContentRoute path="/tutor/main-schedule" component={MainSchedule} />

        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};

export default TutorBasePage;
