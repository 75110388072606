/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
// import { useLocation } from 'react-router';
// import { NavLink } from 'react-router-dom';
// import SVG from 'react-inlinesvg';
// import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';

export function HeaderMenu() {
  // const location = useLocation();
  // const getMenuItemActive = url => {
  //   return checkIsActive(location, url) ? 'menu-item-active' : '';
  // };

  return (
    <div
      id="kt_header_menu"
      className="header-menu header-menu-mobile"
      // {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      {/*end::Header Nav*/}
    </div>
  );
}
