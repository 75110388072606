/* eslint-disable react/prop-types */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { checkIsActive } from '../../../../_helpers';

export function TutorAsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false, exact = false) => {
    return checkIsActive(location, url, exact) ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open ` : '';
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* <li className={`menu-item ${getMenuItemActive('/dashboard', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/tutor/schedule', false, true)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/tutor/schedule">
            <span className="menu-text">Jadwal Bulan Ini</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/tutor/schedule/create', false, true)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/tutor/schedule/create">
            <span className="menu-text">Buat Jadwal Bulan Depan</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/tutor/main-schedule/update', false, true)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/tutor/main-schedule/update">
            <span className="menu-text">Ganti Jadwal Utama</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/tutor/attendance', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/tutor/attendance">
            <span className="menu-text">Riwayat Kehadiran</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/tutor/karte', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/tutor/karte">
            <span className="menu-text">Karte Siswa</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/tutor/manage-student/point', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link" to="/tutor/manage-student/point">
            <span className="menu-text">Poin Siswa</span>
          </NavLink>
        </li>

        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/tutor/student', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/tutor/student">
            <span className="menu-text">Database Siswa</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Custom */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">Custom</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
