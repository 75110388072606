import React from 'react';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import '../../../_metronic/_assets/sass/pages/error/error-6.scss';
import { Link } from 'react-router-dom';

export function ErrorPage6() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/error/bg6.jpg')})`,
        }}
      >
        <div className="d-flex flex-column flex-row-fluid text-center align-items-center">
          <h1 className="error-title font-weight-boldest text-white mb-12" style={{ marginTop: '5rem' }}>
            Unauthorized Access...
          </h1>
          <p className="display-4 font-weight-bold text-white">
            Looks like you don't have permission
            <br />
            to access the page you are looking at.
          </p>
          <Link className="btn btn-lg btn-warning w-50 mt-5" to="/dashboard">
            Back to Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
}
