import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import ToasterContext from '@context/toaster';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = () => ({
  success: {
    backgroundColor: 'green',
  },
  error: {
    backgroundColor: 'red',
  },
  info: {
    backgroundColor: 'blue',
  },
  warning: {
    backgroundColor: 'orange',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: '5px',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          <div className="d-flex flex-column">
            {Array.isArray(message) ? message.map(item => <div key={item}>{item}</div>) : message}
          </div>
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  onClose: PropTypes.func,
};

MySnackbarContent.defaultProps = {
  className: '',
  message: null,
  onClose: () => {},
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);
const styles2 = () => ({
  margin: {
    margin: '10px',
  },
});

const AppSnackbar = () => {
  const { shownDuration, display, variant, text, closeSnackbar } = useContext(ToasterContext);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    closeSnackbar();
  };

  const isMobile = window.innerWidth <= 500;
  const verticalAnchorOrigin = isMobile ? 'bottom' : 'top';
  const horizontalAnchorOrigin = isMobile ? 'right' : 'center';

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: verticalAnchorOrigin,
          horizontal: horizontalAnchorOrigin,
        }}
        open={display}
        autoHideDuration={shownDuration}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={variant} // error, warning, info, success
          message={text}
        />
      </Snackbar>
    </div>
  );
};

export default withStyles(styles2)(AppSnackbar);
