import React from 'react';
import ReactDOM from 'react-dom';
import { MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider } from './_metronic/layout';
import 'socicon/css/socicon.css';

import App from './App';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@assets/styles/index.scss';
import '@assets/styles/tailwind.output.css';
import '@metronic/_assets/plugins/keenthemes-icons/font/ki.css';

ReactDOM.render(
  <MetronicLayoutProvider>
    <MetronicSubheaderProvider>
      <MetronicSplashScreenProvider>
        <App />
      </MetronicSplashScreenProvider>
    </MetronicSubheaderProvider>
  </MetronicLayoutProvider>,
  document.getElementById('root'),
);
