import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ['Poppins'].join(','),
    },

    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        //  main: '#17c191',
        // dark: will be calculated from palette.primary.main,

        main: '#3F4254',
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: '#3783e7',
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: '#f018a6',
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      action: {
        main: '#17c191',
      },
      text: {
        disabled: 'rgba(0, 0, 0, 0.6)',
      },
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: false, // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1,
      },
    },

    /**
     * Overrides
     */
    overrides: {
      MuiPickersDay: {
        daySelected: {
          backgroundColor: 'white',
          color: 'black',
          '&:hover': {
            backgroundColor: 'wheat',
          },
        },
      },
      MuiPickersBasePicker: {
        containerLandscape: {
          justifyContent: 'center',
        },
      },
      MuiSvgIcon: {
        colorAction: {
          color: '#17c191',
        },
      },
      MuiPaper: {
        outlined: {
          border: '1px solid darkgrey',
        },
      },
      MuiTableCell: {
        root: {
          border: '1px solid rgba(224, 224, 224, 1)',
        },
      },
    },
  },
);

export function MaterialThemeProvider(props) {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
