import { queryCache } from 'react-query';

export const networkError = err => {
  console.log(err);
};
export const networkSuccess = (res, showSnackbar, redirectFn = null, text) => {
  if (res?.status || res?.result) {
    showSnackbar({
      text: res?.msg || text,
      duration: 3000,
    });
    if (typeof redirectFn === 'function') redirectFn();
  } else {
    showSnackbar({
      text: res?.msg || text,
      duration: 3000,
      variant: 'error',
    });
  }
};
export const renewCache = (queryKey, variables, data) => {
  queryCache.setQueryData([queryKey, variables], data);
};
