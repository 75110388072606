import React, { Suspense, lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const MyProfile = lazy(() => import('@pages/MyProfile'));

/* Master Data */
const Wilayah = lazy(() => import('@pages/MasterData/Wilayah'));
const Banner = lazy(() => import('@pages/MasterData/Banner'));
const Province = lazy(() => import('@pages/Province'));
const City = lazy(() => import('@pages/City'));
const District = lazy(() => import('@pages/District'));
const Reward = lazy(() => import('@pages/Reward'));
const QuotaSession = lazy(() => import('@pages/QuotaSession'));
const Promo = lazy(() => import('@pages/MasterData/Promotion/Promo'));
const PromoUpgradePaket = lazy(() => import('@pages/MasterData/Promotion/UpgradePaket'));

/* Manage Schedule */
const TutorSchedule = lazy(() => import('@pages/ScheduleManagement/TutorSchedule'));
const ICSSchedule = lazy(() => import('@pages/ScheduleManagement/ICSSchedule'));
const StudentSchedule = lazy(() => import('@pages/ScheduleManagement/StudentSchedule'));
const ChangeScheduleTutor = lazy(() => import('@pages/ScheduleManagement/ChangeScheduleTutor'));
const Calendar = lazy(() => import('@pages/ScheduleManagement/Calendar'));

/* Manage Class */
const JenisKelas = lazy(() => import('@pages/JenisKelas'));
const GrupKelas = lazy(() => import('@pages/GrupKelas'));
const SesiKelas = lazy(() => import('@pages/SesiKelas'));
const Paket = lazy(() => import('@pages/Paket'));
/* Manage Student */
const Student = lazy(() => import('@pages/StudentManagement/Student'));
const RescheduleStudent = lazy(() => import('@pages/RescheduleStudent'));
const KarteStudent = lazy(() => import('@pages/StudentManagement/KarteStudent'));
const PointStudent = lazy(() => import('@pages/StudentManagement/PointStudent'));
const ExchangePoint = lazy(() => import('@pages/StudentManagement/ExchangePoint'));
const FreeTrialStudent = lazy(() => import('@pages/StudentManagement/FreeTrialStudent'));
const SessionShortage = lazy(() => import('@pages/StudentManagement/SessionShortage'));
/* Manage Tutor */
const Tutor = lazy(() => import('@pages/Tutor'));
const RatingTutor = lazy(() => import('@pages/RatingTutor'));
const AttendanceTutor = lazy(() => import('@pages/AttendanceTutor'));
const ScheduleMonitoring = lazy(() => import('@pages/TutorManagement/ScheduleMonitoring'));

const ImportSchedule = lazy(() => import('@pages/ImportSchedule'));
const Staff = lazy(() => import('@pages/Staff'));
const Notification = lazy(() => import('@pages/Notification'));
const Role = lazy(() => import('@pages/Role'));
const StudentPayment = lazy(() => import('@pages/StudentPayment'));
const Report = lazy(() => import('@pages/Report'));

const Dashboard = lazy(() => import('@pages/Dashboard'));

/* LTT */
const LTTQuestions = lazy(() => import('@pages/LTTQuestions'));
const LTTGelombang = lazy(() => import('@pages/LTTGelombang'));
const LTTPeserta = lazy(() => import('@pages/LTTPeserta'));
const LTTReport = lazy(() => import('@pages/LTTReport'));

const BasePage = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />

        <ContentRoute path="/dashboard" component={Dashboard} />
        <ContentRoute path="/my-profile" component={MyProfile} />

        <ContentRoute path="/master-data/wilayah" component={Wilayah} />
        <ContentRoute path="/master-data/banner" component={Banner} />
        <ContentRoute path="/master-data/province" component={Province} />
        <ContentRoute path="/master-data/city" component={City} />
        <ContentRoute path="/master-data/district" component={District} />
        <ContentRoute path="/master-data/reward" component={Reward} />
        <ContentRoute path="/master-data/quota-session" component={QuotaSession} />
        <ContentRoute path="/master-data/promo" component={Promo} />
        <ContentRoute path="/master-data/promo-upgrade-paket" component={PromoUpgradePaket} />

        <ContentRoute path="/manage-schedule/tutor" component={TutorSchedule} />
        <ContentRoute path="/manage-schedule/kegiatan" component={ICSSchedule} />
        <ContentRoute path="/manage-schedule/student" component={StudentSchedule} />
        <ContentRoute path="/manage-schedule/change-schedule-tutor" component={ChangeScheduleTutor} />
        <ContentRoute path="/manage-schedule/calendar" component={Calendar} />

        <ContentRoute path="/manage-class/jenis-kelas" component={JenisKelas} />
        <ContentRoute path="/manage-class/grup-kelas" component={GrupKelas} />
        <ContentRoute path="/manage-class/sesi-kelas" component={SesiKelas} />
        <ContentRoute path="/manage-class/paket" component={Paket} />

        <ContentRoute path="/manage-student/student" component={Student} />
        <ContentRoute path="/manage-student/reschedule" component={RescheduleStudent} />
        <ContentRoute path="/manage-student/karte" component={KarteStudent} />
        <ContentRoute path="/manage-student/point" component={PointStudent} />
        <ContentRoute path="/manage-student/exchange-point" component={ExchangePoint} />
        <ContentRoute path="/manage-student/free-trial" component={FreeTrialStudent} />
        <ContentRoute path="/manage-student/session-shortage" component={SessionShortage} />

        <ContentRoute path="/manage-tutor/tutor" component={Tutor} />
        <ContentRoute path="/manage-tutor/rating" component={RatingTutor} />
        <ContentRoute path="/manage-tutor/attendance" component={AttendanceTutor} />
        <ContentRoute path="/manage-tutor/schedule-monitoring" component={ScheduleMonitoring} />

        <ContentRoute path="/import-schedule" component={ImportSchedule} />
        <ContentRoute path="/role" component={Role} />
        <ContentRoute path="/staff" component={Staff} />
        <ContentRoute path="/notification" component={Notification} />
        <ContentRoute path="/student-payment" component={StudentPayment} />
        <ContentRoute path="/report" component={Report} />

        <ContentRoute path="/ltt/questions" component={LTTQuestions} />
        <ContentRoute path="/ltt/gelombang" component={LTTGelombang} />
        <ContentRoute path="/ltt/peserta" component={LTTPeserta} />
        <ContentRoute path="/ltt-report" component={LTTReport} />

        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};

export default BasePage;
