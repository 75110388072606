import React, { useEffect, useContext } from 'react';
import { AuthContext } from '@context/auth';
import SplashScreen from '@metronic/_partials/controls/SplashScreen';

const Logout = () => {
  const { handleLogout } = useContext(AuthContext);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return <SplashScreen />;
};

export default Logout;
