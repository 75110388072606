/* eslint-disable */
import { useCookies } from 'react-cookie';
import { AUTH_TOKEN, BASE_URL } from '@constants/index';

/**
 * Hooks to generate fetch interface for react-query library
 * @param {string|Function} urlScheme scheme to generate url, can be string or function
 * @param {object} options Options for fetch API
 *
 * @returns {Promise} Returns fetch interface to be consumed by react-query library
 *
 * @example
 * import { useQuery } from 'react-query';
 * import useFetch from '@hooks/useFetch';
 *
 * const { data, status, error } = useQuery(['userDetail'], useFetch('/user'));
 * -- or --
 * const { data, status, error } = useQuery(['userDetail', { userID: 1 }], useFetch(({ userID }) => `/user/${userID}`));
 */

const MUTATION_REQUESTS = ['POST', 'PUT', 'GET'];

const useFetch = (urlScheme, options = {}) => {
  const [cookies] = useCookies([AUTH_TOKEN]);
  const cookie = cookies[AUTH_TOKEN];

  return (key, variables) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const usedVariables = MUTATION_REQUESTS.includes(options.method) ? key : variables;

    let finalUrl = '';

    if (usedVariables && typeof urlScheme === 'function') {
      finalUrl = urlScheme(options?.endpoint, usedVariables);
    } else {
      finalUrl = String(urlScheme);
    }

    // create form-data
    const formData = new FormData();
    if (usedVariables && options?.contentType === 'form-data') {
      const keys = Object.keys(usedVariables);
      for (const key of keys) {
        const value = usedVariables[key];

        if (Array.isArray(value)) formData.append(key, JSON.stringify(usedVariables[key]));
        else formData.append(key, usedVariables[key]);
      }
    }
    const contentType = options?.contentType === 'form-data' ? {} : { 'Content-Type': 'application/json' };
    const method = options?.method && { method: options.method };

    const promise = fetch(finalUrl, {
      signal,
      ...method,
      ...(MUTATION_REQUESTS.includes(options.method) &&
        options?.method !== 'GET' && {
          body: options?.contentType === 'form-data' ? formData : JSON.stringify(usedVariables),
        }),
      headers: new Headers({
        'x-api-key': AUTH_TOKEN,
        ...(cookie?.token && { Authorization: `Bearer ${cookie.token}` }),
        ...(options.headers || {}),
        ...contentType,
      }),
    })
      .then(res => {
        if (res?.status === 401) {
          window.location.assign('/logout');
        }
        if (res?.status === 403) {
          window.location.assign('/unauthorized');
        }
        return res.json();
      })
      .catch(error => {
        console.log(error);
        if (window.__clientLogger) {
          let originalUrl = finalUrl;

          try {
            const { origin, pathname } = new URL(finalUrl);
            originalUrl = `${origin}${pathname}`;
          } catch {
            // do nothing
          }

          window.__clientLogger.notify({
            error: {
              message: `Error occurred for API: ${originalUrl}`,
              name: '[Bennese Admin]',
            },
            params: {
              error,
              apiUrl: finalUrl,
              apiToken: cookie?.token || '',
              variables: usedVariables,
            },
          });
        }
      });

    promise.cancel = controller.abort;

    return promise;
  };
};

export default useFetch;
