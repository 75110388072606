import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDOFOjQejkv0g_Kbs8Dz5PbGZn3e4R4kFE',
  authDomain: 'shinkenjuku-dev-webapp.firebaseapp.com',
  projectId: 'shinkenjuku-dev-webapp',
  storageBucket: 'shinkenjuku-dev-webapp.appspot.com',
  messagingSenderId: '553060026885',
  appId: '1:553060026885:web:7232cf73a9e00ef3c66a6c',
};

export const app = initializeApp(firebaseConfig);
export async function getFCMToken() {
  if (typeof window !== 'undefined') {
    // if (typeof window !== 'undefined' && !app.apps.length) {
    const messaging = getMessaging();

    try {
      const token = await getToken(messaging, {
        vapidKey: 'BEsqlYV90cyBmD40ku8gTV4aS-B9lrz5hbBwumK027khA_4Ei-zf4gfrypT3trsA5KycdiKVYYRG9TckgyxOOLk',
      });
      // console.log('TOKEN FIREBASE : ', token);
      return token;
    } catch (e) {
      console.log('getFCMToken error', e);
      return undefined;
    }
  }
}
