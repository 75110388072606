import React from 'react';
import { CircularProgress } from '@material-ui/core';

export function SplashScreen() {
  return (
    <div id="splash-screen">
      <CircularProgress className="splash-spinner" />
    </div>
  );
}

export default SplashScreen;
