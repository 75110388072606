import React, { useContext } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { AuthContext } from '@context/auth';
import { Layout } from '@metronic/layout';
import { ErrorPage1 as ErrorPage } from '@metronic/modules/ErrorsExamples/ErrorPage1';
import { ErrorPage6 as UnauthorizedPage } from '@metronic/modules/ErrorsExamples/ErrorPage6';
import { Logout, AuthPage } from './Auth';

import BasePage from './BasePage';
import TutorBasePage from './TutorBasePage';

import Snackbar from '@components/common/Snackbar';
export default function Routes() {
  const { isAuth, user } = useContext(AuthContext);
  return (
    <>
      <Switch>
        {!isAuth ? <AuthPage /> : <Redirect from="/auth" to="/" />}

        <Route path="/error" component={ErrorPage} />
        <Route path="/logout" component={Logout} />
        <Route path="/unauthorized" component={UnauthorizedPage} />

        {!isAuth ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          <Layout>{user?.role_id !== 999 ? <BasePage /> : <TutorBasePage />}</Layout>
        )}
      </Switch>
      <Snackbar />
    </>
  );
}
