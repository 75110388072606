/* eslint-disable react/prop-types */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import Person from '@material-ui/icons/Person';
import ReceiptIcon from '@material-ui/icons/Receipt';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import MenuBookIcon from '@material-ui/icons/MenuBook';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open ` : '';
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* <li className={`menu-item ${getMenuItemActive('/dashboard', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/master-data', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <div className="menu-link menu-toggle">
            <span className="svg-icon menu-icon">
              <SVG cacheRequests={true} loader="" src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
            </span>
            <span className="menu-text">Master Data</span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Master Data</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/master-data/province', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/master-data/province">
                  <span className="menu-text">Provinsi / Kota / Kecamatan</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/master-data/wilayah', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/master-data/wilayah">
                  <span className="menu-text">Wilayah</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/master-data/reward', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/master-data/reward">
                  <span className="menu-text">Hadiah</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  '/master-data/quota-session/update',
                  true,
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/master-data/quota-session/update">
                  <span className="menu-text">Batas Waktu Kuota Sesi</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/master-data/promo', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <div className="menu-link menu-toggle">
                  <span className="menu-text">Promo</span>
                  <i className="menu-arrow" />
                </div>
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive('/master-data/promo', true)}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/master-data/promo">
                        <span className="menu-text">General Promo</span>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                        '/master-data/promo-upgrade-paket',
                        true,
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/master-data/promo-upgrade-paket">
                        <span className="menu-text">Upgrade Paket</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/master-data/banner', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/master-data/banner">
                  <span className="menu-text">Banner</span>
                </NavLink>
              </li>

              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-schedule', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <div className="menu-link menu-toggle">
            <span className="svg-icon menu-icon">
              <SVG cacheRequests={true} loader="" src={toAbsoluteUrl('/media/svg/icons/Code/Time-schedule.svg')} />
            </span>
            <span className="menu-text">Manajemen Jadwal</span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Manajemen Jadwal</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-schedule/kegiatan', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-schedule/kegiatan">
                  <span className="menu-text">Jadwal Kegiatan (Paket Entry)</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-schedule/student', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-schedule/student">
                  <span className="menu-text">Jadwal Siswa</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  '/manage-schedule/tutor/current-month',
                  true,
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-schedule/tutor/current-month">
                  <span className="menu-text">Jadwal Tutor Bulan Ini</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  '/manage-schedule/tutor/next-month',
                  true,
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-schedule/tutor/next-month">
                  <span className="menu-text">Jadwal Tutor Bulan Depan</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  '/manage-schedule/change-schedule-tutor',
                  true,
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-schedule/change-schedule-tutor">
                  <span className="menu-text">Ganti Jadwal Tutor</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-schedule/calendar', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-schedule/calendar">
                  <span className="menu-text">Kalender</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-class', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <div className="menu-link menu-toggle">
            <span className="svg-icon menu-icon">
              <SVG cacheRequests={true} loader="" loader="" src={toAbsoluteUrl('/media/svg/icons/Home/Chair1.svg')} />
            </span>
            <span className="menu-text">Manajemen Kelas</span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Manajemen Kelas</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-class/jenis-kelas', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-class/jenis-kelas">
                  <span className="menu-text">Jenis</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-class/sesi-kelas', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-class/sesi-kelas">
                  <span className="menu-text">Sesi</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-class/paket', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-class/paket">
                  <span className="menu-text">Paket</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-student', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <div className="menu-link menu-toggle">
            <span className="svg-icon menu-icon">
              <SVG cacheRequests={true} loader="" src={toAbsoluteUrl('/media/svg/icons/General/User.svg')} />
            </span>
            <span className="menu-text">Manajemen Siswa</span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Manajemen Siswa</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-student/student', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-student/student">
                  <span className="menu-text">Data Siswa</span>
                </NavLink>
              </li>
              {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-student/reschedule', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-student/reschedule">
                  <span className="menu-text">Reschedule Jadwal Siswa</span>
                </NavLink>
              </li> */}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-student/point', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-student/point">
                  <span className="menu-text">Poin Siswa</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-student/exchange-point', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-student/exchange-point">
                  <span className="menu-text">Penukaran Hadiah</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-student/karte', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-student/karte">
                  <span className="menu-text">Karte Siswa</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-student/free-trial', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-student/free-trial">
                  <span className="menu-text">Free Trial</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-student/session-shortage', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-student/session-shortage">
                  <span className="menu-text">Kekurangan Sesi Siswa</span>
                </NavLink>
              </li>

              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-tutor', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <div className="menu-link menu-toggle">
            <span className="svg-icon menu-icon">
              <SVG cacheRequests={true} loader="" src={toAbsoluteUrl('/media/svg/icons/Code/Spy.svg')} />
            </span>
            <span className="menu-text">Manajemen Tutor</span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Manajemen Tutor</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-tutor/tutor', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-tutor/tutor">
                  <span className="menu-text">Data Tutor</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-tutor/rating', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-tutor/rating">
                  <span className="menu-text">Rating Tutor</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/manage-tutor/attendance', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-tutor/attendance">
                  <span className="menu-text">Kehadiran Tutor</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  '/manage-tutor/schedule-monitoring',
                  true,
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/manage-tutor/schedule-monitoring">
                  <span className="menu-text">Monitoring Jadwal Tutor</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/import-schedule', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/import-schedule">
            <span className="svg-icon menu-icon">
              <ReceiptIcon />
            </span>
            <span className="menu-text">Import Schedule</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/staff', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/staff">
            <span className="svg-icon menu-icon">
              <Person />
            </span>
            <span className="menu-text">Staff</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive('/notification', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/notification">
            <span className="svg-icon menu-icon">
              <NotificationsNoneIcon />
            </span>
            <span className="menu-text">Notification</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/student-payment', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/student-payment">
            <span className="svg-icon menu-icon">
              <ReceiptIcon />
            </span>
            <span className="menu-text">Pembayaran Siswa</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/report', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <div className="menu-link menu-toggle">
            <span className="svg-icon menu-icon">
              <SVG cacheRequests={true} loader="" src={toAbsoluteUrl('/media/svg/icons/Code/Spy.svg')} />
            </span>
            <span className="menu-text">Report</span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Report</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/report/data-siswa', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/report/data-siswa">
                  <span className="menu-text">Data Siswa</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/report/data-regular', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/report/data-regular">
                  <span className="menu-text">Data Regular</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/report/fee-tutor', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/report/fee-tutor">
                  <span className="menu-text">Fee Tutor</span>
                </NavLink>
              </li>

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/report/sales-report', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/report/sales-report">
                  <span className="menu-text">Sales Report</span>
                </NavLink>
              </li>

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/report/opr-report', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/report/opr-report">
                  <span className="menu-text">OPR Report</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/ltt', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <div className="menu-link menu-toggle">
            <span className="svg-icon menu-icon">
              <MenuBookIcon />
            </span>
            <span className="menu-text">Tes Diagnostik Anak</span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Tes Diagnostik Anak</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/ltt/questions', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/ltt/questions">
                  <span className="menu-text">Soal</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/ltt/gelombang', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/ltt/gelombang">
                  <span className="menu-text">Gelombang</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/ltt/peserta', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/ltt/peserta">
                  <span className="menu-text">Peserta</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/ltt-report', true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <div className="menu-link menu-toggle">
                  <span className="menu-text">Report Sekolah</span>
                  <i className="menu-arrow" />
                </div>
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li className="menu-item  menu-item-parent" aria-haspopup="true">
                      <span className="menu-link">
                        <span className="menu-text">Upload School Report</span>
                      </span>
                    </li>
                    <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive('/ltt-report/school-report', true)}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/ltt-report/school-report">
                        <span className="menu-text">Upload School Report</span>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive('/ltt-report/school-report', true)}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link " to="/ltt-report/school-report-priority-2">
                        <span className="menu-text">School Report</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>

              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* Custom */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">Custom</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
