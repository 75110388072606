import React from 'react';
import { AuthProvider } from '@context/auth';
import { ToasterProvider } from '@context/toaster';

function ContextProvider({ children }) {
  return (
    <AuthProvider>
      <ToasterProvider>{children}</ToasterProvider>
    </AuthProvider>
  );
}

export default ContextProvider;
