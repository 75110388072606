import { v4 as uuidv4 } from 'uuid';
import transformConstants from './transform';

const transformedClientConstants = transformConstants(process.env);

const constants = {
  ...transformedClientConstants,
  TRANSACTION_ID: uuidv4(),
  AUTH_TOKEN: 'benesse2020',
  BASE_URL: process.env.PUBLIC_URL ? new URL(process.env.PUBLIC_URL).pathname : '',
};

export const {
  TRANSACTION_ID,
  AUTH_TOKEN,
  REACT_APP_RESTAPI_HOST,
  LOG_PROJECT_ID,
  LOG_PROJECT_KEY,
  BASE_URL,
  SISWA_URL,
  ENV,
} = constants;
