import { registerServiceWorker } from '@worker';
import loadPolyfills from './polyfills';
import initLogger from './logger';

try {
  (async () => {
    await loadPolyfills();

    /**
     * Using webpackMode: "eager" will cause this dynamic import to be part of the main bundle,
     * not creating its own chunk
     */
    initLogger();
    await import(/* webpackMode: "eager" */ './bootstrap');
  })();
} catch (error) {
  console.log('[Bootstrap] Error:', error.toString());
  console.log(error.stack);
} finally {
  try {
    console.log('Registering service worker');
    registerServiceWorker();

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('./firebase-messaging-sw.js')
        .then(function(registration) {
          console.log('Registration [firebase-messaging-sw] successful, scope is:', registration.scope);
        })
        .catch(function(err) {
          console.log('Service worker registration failed, error:', err);
        });
    }
  } catch (error) {
    console.log('[Service Worker] Error:', error.toString());
    console.log(error.stack);
  }
}
