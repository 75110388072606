import { Notifier } from '@airbrake/browser';
import getQueryValue from '@utils/getQueryValue';
import { LOG_PROJECT_ID, LOG_PROJECT_KEY, ENV } from '@constants/index';

function initLogger() {
  const isLoggerDisabled = ENV === 'development' && !Number(getQueryValue(window.location, 'debug') || 0);

  if (!LOG_PROJECT_KEY || !LOG_PROJECT_ID || isLoggerDisabled) return;
  if (!window.__clientLogger) {
    window.__clientLogger = new Notifier({
      projectId: LOG_PROJECT_ID,
      environment: process.env.ENV,
      projectKey: LOG_PROJECT_KEY,
    });
  }

  return !LOG_PROJECT_KEY || !LOG_PROJECT_ID ? null : window.__clientLogger;
}

export default initLogger;
