import React, { useMemo, useContext } from 'react';
import { AsideMenuList } from './AsideMenuList';
import { TutorAsideMenuList } from './TutorAsideMenuList';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import AuthContext from '@context/auth';

export function AsideMenu() {
  const { user } = useContext(AuthContext);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes('aside_menu'),
      ulClasses: uiService.getClasses('aside_menu_nav', true),
      asideClassesFromConfig: uiService.getClasses('aside_menu', true),
    };
  }, [uiService]);
  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {user?.role_id !== 999 ? (
          <AsideMenuList layoutProps={layoutProps} />
        ) : (
          <TutorAsideMenuList layoutProps={layoutProps} />
        )}
      </div>
      {/* end::Menu Container */}
    </>
  );
}
