/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useContext, useState, useEffect } from 'react';
import { AuthContext } from '@context/auth';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { UserProfileDropdown } from './dropdowns/UserProfileDropdown';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { IconButton, Badge, Menu, MenuItem, ListItemText, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { NOTIF_ADMIN, SAVE_TOKEN_FCM } from '@pages/Notification/constants/endpoint';
import useFetch from '@hooks/useFetch';
import { networkError } from '@utils/networkCallback';
import { useMutation } from 'react-query';
import Skeleton from '@material-ui/lab/Skeleton';
import { getFCMToken } from '@utils/getFCMToken';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
      '& .MuiListItemIcon-root, & .MuiListItemText-secondary': {
        color: theme.palette.common.white,
      },
    },
    // "&:selected": {
    //   backgroundColor: "yellow"
    // }
  },
}))(MenuItem);

export default function QuickUserToggler() {
  const [
    handleSaveToken,
    {
      // statusSaveTokenFcm
    },
  ] = useMutation(useFetch(`${SAVE_TOKEN_FCM.path}`, { method: SAVE_TOKEN_FCM.method }), {
    onError: networkError,
    // onSuccess: res => {
    // console.log('success save token fcm : ', res);
    // },
  });

  const handleSave = async value => {
    handleSaveToken({
      token: value,
    });
  };

  useEffect(() => {
    getFCMToken()
      .then(response => {
        handleSave(response);
      })
      .catch(error => {
        console.log('error get token fcm : ', error);
      });
  }, []);

  //*
  const { user } = useContext(AuthContext);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, 'extras.user.layout') === 'offcanvas',
    };
  }, [uiService]);

  const fullName = `${user.user_full_name}`;

  //*
  const [anchorElNotifBell, setAnchorElNotifBell] = React.useState(null);

  const handleCloseMenuNotifBell = () => {
    setAnchorElNotifBell(null);
  };

  const [listNotif, setListNotif] = useState([]);
  const [handleGetListNotif, { status }] = useMutation(
    useFetch(`${NOTIF_ADMIN.path}?page=1&rows_limit=3`, { method: NOTIF_ADMIN.method }),
    {
      onError: networkError,
      onSuccess: res => {
        setListNotif(res?.data || []);
      },
    },
  );

  const handleOpenMenuNotifBell = event => {
    setAnchorElNotifBell(event.currentTarget);
    handleGetListNotif();

    setValueDigitBadge(0);
  };

  const [valueDigitBadge, setValueDigitBadge] = useState(0);

  if (navigator) {
    navigator.serviceWorker.addEventListener('message', function(event) {
      // console.log('Data from SW', event?.data);
      setValueDigitBadge(+event?.data?.count || 1);
    });
  }

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <IconButton color="primary" size="medium" onClick={handleOpenMenuNotifBell}>
            <Badge badgeContent={valueDigitBadge} color="error">
              <NotificationsNoneIcon />
            </Badge>
          </IconButton>
          <div
            className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            id="kt_quick_user_toggle"
          >
            <>
              <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
              <span className="text-dark-50 font-weight-bolder font-size-base mr-3">{fullName}</span>
            </>
          </div>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorElNotifBell}
            keepMounted
            open={Boolean(anchorElNotifBell)}
            onClose={handleCloseMenuNotifBell}
          >
            {status === 'loading' && (
              <div className="p-3">
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={270} />
                <Skeleton variant="text" width={300} />
              </div>
            )}
            {status === 'success' &&
              listNotif?.map((item, index) => (
                <StyledMenuItem key={index}>
                  <Link to="/notification">
                    <ListItemText
                      primary={
                        <>
                          <Typography variant="subtitle2">{item?.date}</Typography>
                          <Typography variant="subtitle1">{item?.title}</Typography>
                        </>
                      }
                      secondary={item?.body}
                    />
                  </Link>
                </StyledMenuItem>
              ))}
            {status !== 'loading' ? (
              <Link to="/notification">
                <p className="text-center mt-5" onClick={handleCloseMenuNotifBell}>
                  <u>Lihat Semua</u>
                </p>
              </Link>
            ) : null}
          </StyledMenu>
        </div>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
