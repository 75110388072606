import React from 'react';
// import { useQuery } from 'react-query';
import { useCookies } from 'react-cookie';
// import SplashScreen from '@metronic/_partials/controls/SplashScreen';
import { AUTH_TOKEN, BASE_URL } from '@constants/index';
// import useFetch from '@hooks/useFetch';

const defaultContext = {
  handleLogout: () => {},
  token: '',
  user: {},
};

export const AuthContext = React.createContext(defaultContext);

export const AuthProvider = props => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;
  const [cookies, , removeCookie] = useCookies([AUTH_TOKEN]);
  // const { data, isLoading, isError } = useQuery(['userDetail'], useFetch(`${REACT_APP_RESTAPI_HOST}/me`));

  const cookie = cookies[AUTH_TOKEN];

  // if (isLoading) {
  //   return <SplashScreen />;
  // }
  // if (!token && !userData) {
  //   window.location.href = `${BASE_URL}/login`;

  //   return null;
  // }

  const handleLogout = () => {
    removeCookie(AUTH_TOKEN, {
      path: '/',
    });

    window.location.assign(BASE_URL);
  };

  const employeeData = {
    id: '1',
    ...(cookie?.user || {}),
  };

  return (
    <AuthContext.Provider
      value={{
        user: employeeData,
        handleLogout,
        token: cookie?.token || null,
        isAuth: cookie?.token && Boolean(employeeData.id),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
